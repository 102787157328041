<template>
  <div class="search-bar-video">
    <div class="search-bar">
      <div class="search-bar-left">
        <TreeSelect 
          class="device" 
          v-model="selectedDevices" 
          :title="$t('search_device') /*設備*/"
          :data="deviceTreeList" 
          :groupList="groupList"  
          :isGroupSelect="true"
          :isMultipleSelect="true"
          optionWidth="150%"
        />
        <TreeSelect 
          class="device" 
          v-model="selectedGroup" 
          :title="$t('history_device_group') /*設備群組*/"
          :data="deviceGroupList" 
          :groupList="groupList"  
          :isGroupSelect="true"
          :isMultipleSelect="false"
          :defaultProps="deviceGroupProps"
          optionWidth="150%"
        />
      
        <DateRangeSelect 
          class="dt-select" 
          v-model="videoDateTime" 
          :range="true"
          :title="$t('search_time_interval')" 
          :maxRangeDays="maxRangeDays"
          :enabledDateStart="enabledDateStart" 
          :enabledDateEnd="enabledDateEnd" 
        />
        <MagnifierSearchBtn 
          :active="searchLoading"
          @click="searchVideo()" 
        />
        <TooltipElement :content="$t('search_clear')/*清除*/" alwaysShow placement="bottom">
          <div class="tool" @click="earseSearchParams">
            <img src="@/assets/icons/erase-white.svg" alt="">
          </div>
        </TooltipElement>
      </div>
      <div 
        class="download-btn" 
        :class="{ disabled: checkCount === 0 }" 
        @click="downloadVideo">
        <img src="@/assets/icons/download.svg" alt="">
        {{ $t('download_video')/*影片下載*/ }}
      </div>
    </div>

    <div v-show="historyVideoList.length > 0" class="pages">
      <Pagination
        :currentPage="videoCurrentPage"
        :total="videoTotal"
        :pageSize="pageSize" 
        mode="multiple"
        @getPageData="getPageVideos"
      ></Pagination>
    </div>
    <div v-if="videoTotal > 0" class="select-all">
      <img
        v-if="isCheckAll"
        src="@/assets/icons/checkbox-yellow-checked.svg"
        alt=""
        @click="isCheckAll = !isCheckAll"
      />
      <img
        v-else-if="isIntermediate"
        src="@/assets/icons/checkbox-yellow-minus.svg"
        alt=""
        @click="isCheckAll = false"
      />
      <img
        v-else
        src="@/assets/icons/checkbox-white-square.svg"
        @click="isCheckAll = true"
        alt=""
      />

      <span>{{ $t('video_select_all') }}</span>
      <div class="bar"></div>
      <span>{{ $t('video_selected')/*已勾選*/ }}：{{ checkCount }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { getVideoDateRangeSetting } from '@/config/dateRange.js'
import multiDownload from 'multi-download'

export default {
  name: 'SearchBarVideo',
  components: {
    TreeSelect: () => import('@/components/Base/TreeSelect.vue'),
    DateRangeSelect: () => import('@/components/Base/DateRangeSelect.vue'),
    Pagination: () => import('@/components/Base/Pagination.vue'),
    TooltipElement: () => import('@/components/Base/TooltipElement.vue'),
    MagnifierSearchBtn: () => import('@/components/Base/MagnifierSearchBtn.vue'),
  },
  data() {
    return {
      selectedDevices: [],
      selectedGroup: {}, // 選中的群組
      deviceGroupList: [], // 設備群組列表
      videoDateTime: [],
      maxRangeDays: 180, // 最大可選擇區間天數
      enabledDateStart: null, // 可以查詢開始時間
      enabledDateEnd: null, // 可以查詢結束時間

      deviceGroupProps: {
        children: 'children',
        label: 'label',
      },
    }
  },
  computed: {
    ...mapState(['permissionV2']),
    ...mapState('account', ['groupList', 'deviceTreeList']),
    ...mapState('historyVideo', [
      'searchLoading',
      'pageSize', 
      'videoTotal',
      'videoCurrentPage',
      'historyVideoList',
    ]),
    checkCount() {
      return this.historyVideoList.filter(item => item.isCheck).length
    },
    isCheckAll: {
      get() {
        return this.historyVideoList?.length > 0 && this.checkCount === this.historyVideoList.length
      },
      set(val) {
        this.historyVideoList.forEach((item) => {
          if (item.type === 'MP4') {
            if (item.isCheck === undefined) this.$set(item, 'isCheck', val)
            else item.isCheck = val
          }
        })
      }
    },
    isIntermediate() {
      return this.checkCount > 0 && this.checkCount < this.historyVideoList.length
    },
  },
  watch: {
    
  },
  async mounted() {
    // 0: private, 1: public
    await this.getDeviceTreeList({ public: 0 })
    
    const deviceData = JSON.parse(JSON.stringify(this.deviceTreeList))
    this.deviceGroupList = this.removeEmptyChildren(deviceData)
    this.initDateRange()
  },
  methods: {
    ...mapMutations('historyVideo', ['updateQueryParams']),
    ...mapActions('historyVideo', ['searchVideoList']),
    ...mapActions('video', ['getVideoList']),
    ...mapActions('account', ['getDeviceTreeList']),
    initDateRange() {
      // 影片預設搜尋區間: 預設1週
      // 影片最大可搜尋區間: 預設最近三個月
      // 影片最多可搜尋的範圍: 預設最近1年
      const { defaultDateRange, maxRangeDays, enabledDateStart, enabledDateEnd } 
        = getVideoDateRangeSetting()
      this.videoDateTime = defaultDateRange
      this.maxRangeDays = maxRangeDays
      this.enabledDateStart = enabledDateStart
      this.enabledDateEnd = enabledDateEnd
    },
    setQueryParams() {
      let params = { 
        index: 0,  // 預設從第0筆開始
      }

      // 設備
      if (this.selectedDevices.length > 0) {
        params.postId = this.selectedDevices.map(device => device.id)
      }

      // 設備群組
      if (this.selectedGroup?.id) {
        params.group = [this.selectedGroup.id]
      }

      // 時間區間
      if (this.videoDateTime.length === 2) {
        params.startTime = new Date(this.videoDateTime[0]).toISOString()
        params.stopTime = new Date(this.videoDateTime[1]).toISOString()
      }
      
      // 將搜尋參數存入vuex
      this.updateQueryParams(params)
    },
    searchVideo() {
      // todo: 影片不能一次搜尋全部，要分頁去搜尋
      this.setQueryParams()
      this.searchVideoList({ page: 1 })
    },
    async getPageVideos(payload) {
      if (payload === 0) {
        this.$notify({
          title: this.$t('search_hint')/* 提示 */,
          message: '已在第一頁',
          type: 'warning'
        })
        return
      }
      
      // if (this.currentPage * this.pageSize < (payload - 1) * this.pageSize) {
      //   this.$notify({
      //     title: this.$t('search_hint')/* 提示 */,
      //     message: '無下一頁資料',
      //     type: 'warning'
      //   })
      //   return
      // }

      await this.searchVideoList({ page: payload })
    },
    earseSearchParams() {
      this.selectedDevices = []
      this.selectedGroup = {}
      this.initDateRange()
    },
    removeEmptyChildren(data) {
      return data.filter(node => {
        // 節點有children且長度不為0
        if (node.children && node.children.length > 0) {
            node.children = this.removeEmptyChildren(node.children)
            return true
        }
        return node.children
      })
    },
    downloadVideo() {
      const urls = this.historyVideoList.filter(item => item.isCheck).map(item => item.downloadUrl)
      multiDownload(urls)
    },
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.search-bar-video {
  width: 100%;
  height: 154px; 
  padding: px2rem(8) px2rem(20);
  background: #282942;
  color: #ffffff;
  border-bottom: 1px solid #ffffff33;
}

.search-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: px2rem(12);
  margin-bottom: 16px;
  position: relative;
  
  &-left {
    width: 100%;
    display: flex;
    align-items: flex-end;
    column-gap: px2rem(12);
    position: relative;
  }

  .device {
    max-width: 15.6%;
    min-width: 15.6%;
    @include xxl {
      max-width: 20%;
      min-width: 20%;
    }
  }

  .dt-select {
    width: 26%;
    position: relative;
    @include xxl {
      width: 30%;
    }
  }

  .tool {
    width: px2rem(36);
    height: px2rem(36);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background: #FFFFFF1A;
      border-radius: 8px;
    }
    &:active {
      background: #FFFFFF80;
    }
    img {
      width: px2rem(22);
      height: px2rem(22);
    }
  }

  .download-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: px2rem(36);
    background: #4A5C78;
    padding: 0 px2rem(16);
    border-radius: px2rem(8);
    white-space: nowrap;
    @include font_style(px2rem(16), px2rem(24));
    user-select: none;
    cursor: pointer;

    img {
      width: px2rem(16);
      height: px2rem(16);
      margin-right: px2rem(8);
      vertical-align: baseline;
    }

    &:hover, &:active {
      background: #6E7D93;
    }

    &.disabled {
      background: #4A5C78;
      opacity: 0.2;
      cursor: default;
    }
  }
}

.pages {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.select-all {
  display: flex;
  align-items: center;
  column-gap: 12px;
  font-size: px2rem(18);
  cursor: pointer;
  @include font_style(px2rem(14), px2rem(21));
  .bar {
    width: 1px;
    height: 20px;
    background: #ffffff80;
  }
}

</style>